// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
import Player from '@vimeo/player'; //eslint-disable-line

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
    $that.find('.video--embed').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: $that.width(),
          height: $that.width() / 1.77777,
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  $('.menu-icon').on('click', function () {
    $('body').toggleClass('mobile-body-fixed');
    var headerHeight = $('.menu-grid-container').outerHeight();
    $('.top-bar').css('top', headerHeight + 30);
    // if ($('body').hasClass('admin-bar')) {
    //   $('.top-bar').css('top', headerHeight + 46);
    // }
  });

  let wpAdminBar = $('#wpadminbar');
  if (wpAdminBar.length) {
    $('.header-wrap').css({ top: wpAdminBar.height() });
  } else {
    $('.header-wrap').css({ top: 0 });
  }

  // $('.invalid-password [type="submit"]').on('click', function () {
  //   // jQuery code goes here
  //   let passwordVal = $('input[name="password"]');
  //   $.ajax({
  //     url: ajax_object.ajax_url,
  //     data: {
  //       action: 'load_more_category',
  //       password: passwordVal,
  //     },
  //     type: 'post',
  //     success: function (result) {
  //       result.replaceWith($('main'));
  //     },
  //     error: function (result) {
  //       console.warn(result);
  //     },
  //   });
  // });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  $('.latest-news-section .post-item .post-title').matchHeight();

  $('.page-cards-w-title-section .card-item h3').matchHeight();

  $('.cards-grid-section .card-item h3').matchHeight();
  $('.cards-grid-section .card-item .text').matchHeight();

  $('.search-item').on('click', function (e) {
    e.preventDefault();
    let button = $('.search-item');
    button.parents('.menu-col').find('.search-wrap').toggle('slow');

    // if (searchWrap.hasClass('active') === false) {
    //   searchWrap.addClass('active');
    //   console.log(1);
    //
    //   $(document).mouseup(function (e) {
    //     if ($(window).width() > '640') {
    //       console.log(2);
    //       if (
    //         !searchWrap.has(e.target).length ||
    //         button.has(e.target).length === 1
    //       ) {
    //         searchWrap.removeClass('active');
    //         e.preventDefault();
    //       }
    //     }
    //   });
    // }
  });

  /* global ajax_object */
  $('.invalid-password-form').on('submit', function (e) {
    e.preventDefault();
    // jQuery code goes here
    let passwordVal = $('input[name="password"]').val();

    $.ajax({
      url: ajax_object.ajax_url,
      data: {
        action: 'password',
        password: passwordVal,
      },
      type: 'post',
      success: function (result) {
        if (result.access) {
          location.reload();
        }
        $('main').replaceWith(result.html);
      },
      error: function (result) {
        console.warn(result);
      },
    });
  });

  $(document).on('click', '.cat-list .see-more-posts', function () {
    // let btnLoadMore = $(this);
    let btnLoadMoreCat = $(this).data('category');
    // let catContainer = $('.cat-list').data('category');
    let currCatContainer = $(this).closest('.cat-list');
    let page = $(this).data('paged');
    let currButton = $(this).closest('.more-button-wrap');
    $.ajax({
      type: 'POST',
      url: ajax_object.ajax_url,
      data: {
        action: 'cat_more_posts',
        category: btnLoadMoreCat,
        paged: page,
      },
      beforeSend() {
        // console.log('test sending');
      },
      success: function (response) {
        // console.log('test success');
        currButton.remove();
        currCatContainer.append(response);
      },
    });
  });

  $('.main-loop .main-col .more-button').on('click', function () {
    // jQuery code goes here
    let btnLoadMore = $(this);
    let container = $('.main-loop .main-col ul');

    let postArray = [];
    $('.second-col')
      .find('article')
      .each(function () {
        postArray.push($(this).attr('id'));
      });

    $.ajax({
      url: ajax_object.ajax_url,
      data: {
        action: 'load_more',
        offset: container.find('li').length,
        exclude: postArray,
      },
      type: 'post',
      success: function (result) {
        container.append(result.html);
        if (container.find('li').length >= result.post_count) {
          btnLoadMore.hide();
        }
      },
      error: function (result) {
        console.warn(result);
      },
    });
  });

  $('.categories-col .more-button').on('click', function () {
    // jQuery code goes here
    let btnLoadMore = $(this);
    let container = $('.categories-col .grid-x');

    let categoryArray = [];
    container.find('.category-title').each(function () {
      categoryArray.push($(this).attr('id'));
    });

    $.ajax({
      url: ajax_object.ajax_url,
      data: {
        action: 'load_more_category',
        offset: container.find('.cell').length,
        exclude: categoryArray,
      },
      type: 'post',
      success: function (result) {
        console.log(result.post_count);
        container.append(result.html);
        if (container.find('.cell').length >= result.post_count) {
          btnLoadMore.hide();
        }
      },
      error: function (result) {
        console.warn(result);
      },
    });
  });

  $('.videos-col .more-button').on('click', function () {
    // jQuery code goes here
    let btnLoadMore = $(this);
    let container = $('.videos-col .grid-x');

    let videoArray = [];
    container.find('.preview--video').each(function () {
      videoArray.push($(this).attr('id'));
    });

    $.ajax({
      url: ajax_object.ajax_url,
      data: {
        action: 'load_more_video',
        offset: container.find('.cell').length,
      },
      type: 'post',
      success: function (result) {
        console.log(result);
        container.append(result.html);
        if (container.find('.cell').length >= result.post_count) {
          btnLoadMore.hide();
        }
      },
      error: function (result) {
        console.warn(result);
      },
    });
  });

  $('.featured-post-col .more-button').on('click', function () {
    // jQuery code goes here
    let btnLoadMore = $(this);
    let container = $('.featured-post-col .grid-x');

    let categoryArray = [];
    container.find('.category-title').each(function () {
      categoryArray.push($(this).attr('id'));
    });

    $.ajax({
      url: ajax_object.ajax_url,
      data: {
        action: 'load_more_category_col',
        offset: container.find('.cell').length,
        exclude: categoryArray,
      },
      type: 'post',
      success: function (result) {
        container.append(result.html);
        if (container.find('.cell').length >= result.post_count) {
          btnLoadMore.hide();
        }
      },
      error: function (result) {
        console.warn(result);
      },
    });
  });

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  $('.testimonials-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    fade: true,
    adaptiveHeight: true,
    // asNavFor: '.testimonials-author',
  });
  // $('.testimonials-author').slick({
  //   slidesToShow: $('.number-slides').attr('data-slide-number'),
  //   slidesToScroll: 1,
  //   asNavFor: '.testimonials-slider',
  //   focusOnSelect: true,
  // });

  $('.latest-news-slider').slick({
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 10000,
    cssEase: 'linear',
    infinite: true,
    // waitForAnimate: false,
    // pauseOnHover: true,
    variableWidth: true,
  });

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  $('.video-wrap .play-button').on('click', function () {
    $(this).fadeOut();
    let videoWrap = $(this).parents('.video-wrap');
    videoWrap.find('img').fadeOut();
    let iframe = videoWrap.find('iframe');
    let urlIframe = iframe.attr('src');
    iframe.attr('src', urlIframe.replace('?', '?autoplay=1&loop=1'));
  });

  $('iframe').on('click', function () {
    let videoWrap = $(this).parents('.video-wrap');
    videoWrap.find('img').fadeIn();
    let urlIframe = $(this).attr('src');
    $(this).attr('src', urlIframe.replace('?', '?autopause=0'));
  });

  let iframe = $('.home-hero').find('iframe');
  if (iframe.length > 0) {
    let url = iframe.attr('src');
    iframe.attr(
      'src',
      url.replace(
        '?',
        '?autoplay=1&muted=1&title=0&byline=0&portrait=0&sidedock=0&loop=1&'
      )
    );

    iframe.on('load', function () {
      setTimeout(function () {
        iframe.fadeIn('slow');
      }, 1000);
    });
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
